import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import * as pbi from 'powerbi-client';
import { Subscription } from 'rxjs';
import { Building } from 'src/app/models/habitat/Building.class';
import { DateService } from 'src/app/services/common/date.service';
import { HabitatService } from './../../../services/backend/habitat.service';
import { PowerBiService } from './../../../services/backend/powerbi.service';
import { GlobalService } from './../../../services/common/global.service';
import { InformesService } from './../../../services/pages/informes.service';
import { ScreenSizeService } from './../../../services/pages/screensize.service';
import { QuickSightService } from 'src/app/services/backend/quicksight.service';

declare let powerbi: any;

@Component({
  selector: 'app-advanced-reports',
  templateUrl: './advanced-reports.component.html',
  styleUrls: [],
})
export class AdvancedReportsComponent implements OnInit, OnDestroy {
  screenHeight = 541.25;
  screenWidth = 1140;
  buildingSubs: Subscription;
  dashboardView: any;
  currentBuilding: Building;
  url: string;
  noBuildings = false;
  noFloors = false;
  informPbiSelected = 'Puestos';
  selectedInforme = 'QuickSight'; //'Power BI'
  selectedPageInforme = true;
  @ViewChild('embeddedReport')
  embeddedReport: ElementRef;
  config: any;
  buildings: Building[] = [];

  constructor(
    private quickSightService: QuickSightService,
    private powerBiService: PowerBiService,
    private dateService: DateService,
    private informesService: InformesService,
    private screenSizeService: ScreenSizeService,
    private habitatService: HabitatService,
    private globalService: GlobalService
  ) {
    this.getScreenSize();
    this.informesService.setPageInforme(this.selectedPageInforme);
  }

  ngOnInit(): void {
    this.getDefaultBuilding();
    //this.initializeReportPbi(this.informPbiSelected);

    this.initializeReportQuickSight();
    /*this.informesService.selectedInforme$.subscribe((value) => {
      this.selectedInforme = value;
      if (this.selectedInforme === 'Power BI') {
        this.initializeReportPbi(this.informPbiSelected);
      } else if (this.selectedInforme === 'QuickSight') {
        this.initializeReportQuickSight();
      }
    });*/

    /* this.informesService.informPbiSelected$.subscribe((value) => {
      this.informPbiSelected = value;
      this.initializeReportPbi(this.informPbiSelected);
    }); */
  }

  ngOnDestroy(): void {
    this.informesService.setPageInforme(false);
    // this.informesService.setInforme('Power BI');
    // this.informesService.setInformeType('Puestos');
  }

  initializeReportQuickSight(): void {
    this.quickSightService
      .getQuickSightUrl()
      .toPromise()
      .then((response: any) => {
        this.url = response.url;
        this.dashboard(this.url);
      });
  }

  initializeReportPbi(reportType: string): void {
    this.getBuildings(reportType);
  }

  getBuildings(reportType: string): void {
    this.globalService.userBuildings$.subscribe((buildings: Building[]) => {
      this.buildings = buildings;
      // initializeReportPBI
      this.powerBiService
        .getPowerBiEmbedUrl(reportType.toLowerCase())
        .toPromise()
        .then(
          (reportConfig) => {
            this.buildEmbedConfig(reportConfig);
          },
          (error) => {
            console.log(error);
          }
        );
    });
  }

  getDefaultBuilding(): void {
    this.habitatService.getBuilding(parseInt(localStorage.getItem('hb_selectedBuilding'))).subscribe((building) => {
      this.currentBuilding = building;
    });
  }

  /**
   * Example: reportConfig.url = reportConfig.url?.concat('&filter=FT_AFORO_FLOOR_CITY_BUILDING/building eq "'Novus"'');
   * @param reportConfig
   * @returns
   */
  filterBuildingByUserLogged(reportConfig): any {
    let tableBuilding = '';
    if (this.informPbiSelected === 'Aforo') {
      tableBuilding = 'FT_AFORO_FLOOR_CITY_BUILDING';
    } else if (this.informPbiSelected === 'Reservas') {
      tableBuilding = 'FT_RESERVAS_AGG';
    } else if (this.informPbiSelected === 'Puestos') {
      tableBuilding = 'FT_PUESTOS_AGG';
    }
    const buildingNames = new String(this.buildings.map((building) => building.name).join())
      .split(' ')
      .join('%20')
      .split(',')
      .join('%27,%27');
    reportConfig.url = reportConfig.url?.concat(
      '&filter=' + tableBuilding + '%2Fbuilding%20in%20(%27' + buildingNames + '%27)'
    );
    return reportConfig;
  }

  /**
   * Set up the configuration object that determines what to embed and how to embed it.
   */
  buildEmbedConfig(reportConfig): void {
    reportConfig = this.filterBuildingByUserLogged(reportConfig);
    const model = pbi.models;
    const embedConfig = {
      type: 'report',
      tokenType: model.TokenType.Embed,
      accessToken: reportConfig.token,
      embedUrl: reportConfig.url,
      permissions: model.Permissions.All,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        //actionBarEnabled: true,
        background: model.BackgroundType.Transparent, // BACKGROUND PBIX
        panes: {
          filters: {
            expanded: false,
          },
        },
      },
    };
    const reportClass = 'reportClass';
    const phasedEmbedding = false;
    const eventHandlers = new Map([
      [
        'loaded',
        () => {
          console.log('Report loaded');
        },
      ],
      [
        'rendered',
        () => {
          console.log('Report rendered');
        },
      ],
      [
        'error',
        (event) => {
          console.log(event.detail);
        },
      ],
    ]);
    powerbi.embed(this.embeddedReport.nativeElement, embedConfig, reportClass, phasedEmbedding, eventHandlers);
    const iFrameElement = document.getElementsByTagName('iframe');
    iFrameElement[0].style.borderWidth = '0px';
  }

  buildingChanged($event): void {
    this.currentBuilding = $event;
    if (this.dashboardView) {
      this.dashboardView?.setParameters({
        ParamBuildingURL: this.currentBuilding.name,
        ParamCBuildingURL: this.currentBuilding.name,
        BuildingURL: this.currentBuilding.name,
      });
    } else {
      this.dashboard(this.url);
    }
  }

  dashboard(embeddedURL: string): void {
    if (embeddedURL) {
      const containerDiv = document.getElementById('dashboardContainer');
      const today = this.dateService.getDateDiffDays(this.dateService.getUTCNow(), 0);
      const start = this.dateService.getInitLastYear(today);
      const options = {
        url: embeddedURL,
        container: containerDiv,
        parameters: {
          ParamBuildingURL: this.currentBuilding?.name,
          ParamCBuildingURL: this.currentBuilding?.name,
          BuildingURL: this.currentBuilding?.name,
          SegmentDateEsite: start,
          SegmentDateEsiteFinal: today,
        },
        footerPaddingEnabled: false,
        scrolling: 'yes',
        width: '100%',
        height: 'AutoFit',
        loadingHeight: '700px',
        border: '0',
      };
      this.dashboardView = QuickSightEmbedding.embedDashboard(options);
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth * 0.75;
    this.screenSizeService.setHeight(this.screenHeight);
    this.screenSizeService.setWidth(this.screenWidth);
  }
}
