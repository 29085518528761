<app-space-filter
  *ngIf="selectedInforme === 'QuickSight'"
  [canChangeFloor]="false"
  [canChangeArea]="false"
  (buildingChanged)="buildingChanged($event)"
  (noBuildings)="noBuildings = $event"
  (noFloors)="noFloors = $event"
></app-space-filter>
<mat-card>
    <!-- QuickSight-->
    <div
      style="min-height: 700px; display: flex; width: 100%; align-items: center; justify-content: center"
      *ngIf="!url && selectedInforme === 'QuickSight'"
    >
      <app-loading></app-loading>
    </div>
    <div id="dashboardContainer" class="advanced-informs" *ngIf="selectedInforme === 'QuickSight'"></div>
  </mat-card>

