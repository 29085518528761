import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HabitatService } from './habitat.service';

@Injectable({
  providedIn: 'root',
})
export class QuickSightService {
  constructor(private habitatService: HabitatService) {}

  getQuickSightUrl(): Observable<any> {
    const method = 'v2/quicksight/url';
    return this.habitatService.requestToHabitat('get', method);
  }
}
