import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InformesService {
  private isPageInforme$: Subject<boolean> = new Subject();
  private informe$: Subject<string> = new Subject();
  private informeType$: Subject<string> = new Subject();
  private refresh$: Subject<boolean> = new Subject();
  selectedPageInforme$ = this.isPageInforme$.asObservable();
  selectedInforme$ = this.informe$.asObservable();
  informPbiSelected$ = this.informeType$.asObservable();
  refreshSelected$ = this.refresh$.asObservable();

  constructor() {
    this.isPageInforme$.next(false);
    this.informe$.next('QuickSight');
    this.informeType$.next('QuickSight');
    this.refresh$.next(false);
  }

  setPageInforme(pageInforme: boolean): void {
    this.isPageInforme$.next(pageInforme);
  }

  getPageInforme(): Observable<boolean> {
    return this.selectedPageInforme$;
  }

  setInforme(informe: string): void {
    this.informe$.next(informe);
  }

  getInforme(): Observable<string> {
    return this.selectedInforme$;
  }

  setInformeType(type: string): void {
    this.informeType$.next(type);
  }

  getInformeType(): Observable<string> {
    return this.informPbiSelected$;
  }

  setRefresh(refresh: boolean): void {
    this.refresh$.next(refresh);
  }

  getRefresh(): Observable<boolean> {
    return this.refreshSelected$;
  }
}
