export enum SpaceUseType {
  Desk = 'Desk',
  Room = 'Room',
  Parking = 'Parking',
  Canteen = 'Canteen',
  Other = 'Other',
}

export function spaceUseTypeFromExcelValue(value: string): SpaceUseType {
  switch (value) {
    case 'Puesto':
    case SpaceUseType.Desk:
      return SpaceUseType.Desk;
    case 'Sala':
    case SpaceUseType.Room:
      return SpaceUseType.Room;
    case 'Parking':
    case SpaceUseType.Parking:
      return SpaceUseType.Parking;
    case 'Comedor':
    case SpaceUseType.Canteen:
      return SpaceUseType.Canteen;
    case 'Otro':
      return SpaceUseType.Other;
  }
}
